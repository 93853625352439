import { Box, Button, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { logout } from 'src/redux/actions/authActions';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { theme } from '@ikhokha/commons-ui';
import { Layout } from 'src/components/layout/Layout';

export const WelcomeScreen: React.FunctionComponent<{
  /* empty object*/
}> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state) => state.auth);
  const { referralCode } = useAppSelector((state) => state.referralCode);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLogout = () => {
    setIsLoading(true);
    dispatch(logout()).then(() => {
      setIsLoading(false);
      navigate('/');
    });
  };

  useEffect(() => {}, [referralCode]);

  return (
    <Layout>
      <Container
        sx={{
          alignSelf: 'center',
          alignItems: 'center',
          maxWidth: {
            xs: 'xs',
          },
        }}
      >
        <Box
          sx={{
            color: '#3F3E38',
            marginTop: theme.spacing(0.25),
            marginBottom: theme.spacing(3),
          }}
        >
          <Typography variant="h4">Hi {name}</Typography>
          {!referralCode ? (
            <Typography variant="body1">
              We are getting your referral code...
            </Typography>
          ) : (
            <>
              <Typography variant="body1">Your referral code is:</Typography>
              <Typography variant="h3">{referralCode}</Typography>
            </>
          )}
        </Box>
        {/* Button */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleLogout()}
            disabled={isLoading}
            size="large"
          >
            {isLoading ? 'Logging out...' : 'Logout'}
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

export default WelcomeScreen;
