import { AnyAction, Reducer, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import authSlice from 'src/redux/reducers/authSlice';
import referralCodeSlice from 'src/redux/reducers/referralCodeSlice';
import userDeviceSlice from 'src/redux/reducers/userDeviceSlice';

const authReducerPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'name', 'email'],
};

const referralCodePersistConfig = {
  key: 'referralCode',
  storage,
  whitelist: ['referralCode'],
};

const appReducer = combineReducers({
  auth: persistReducer(authReducerPersistConfig, authSlice),
  referralCode: persistReducer(referralCodePersistConfig, referralCodeSlice),
  userDevice: userDeviceSlice,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/userLogout') {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');
    Object.keys(state).forEach((key: string) => {
      storage.removeItem(`persist:${key}`);
    });
    state = {} as RootState;
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
