/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { login } from 'src/redux/actions/authActions';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { theme } from '@ikhokha/commons-ui';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { emailValidation, passwordValidation } from 'src/utils/validation';
import { Layout } from 'src/components/layout/Layout';
import { FormikField } from 'src/components/Formik/FormikField';
import { ErrorMessage } from 'src/components/ErrorMessage';

export const Login: React.FunctionComponent<{
  /* empty object*/
}> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    isAuthenticating,
    authenticationSuccess,
    authenticationErrorMessage,
  } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticating && authenticationSuccess) {
      navigate('/welcome');
    }
  }, [isAuthenticating]);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: emailValidation,
    password: passwordValidation,
  });

  const onSubmit = (values: { email: string; password: string }) => {
    const { email, password } = values;
    const _email = email.trim().toLowerCase();
    const _password = password.trim();
    dispatch(login(_email, _password));
  };

  return (
    <Layout headerText="Login to your account">
      {authenticationErrorMessage && (
        <ErrorMessage message={authenticationErrorMessage} />
      )}

      <Stack
        sx={{
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnBlur={true}
        >
          {(formik: any) => {
            return (
              <Form>
                <Box>
                  <FormikField
                    label="Email"
                    name="email"
                    type="text"
                  ></FormikField>
                  <FormikField
                    label="Password"
                    name="password"
                    type="password"
                  ></FormikField>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    disabled={isAuthenticating || !formik.isValid}
                    size="large"
                  >
                    {isAuthenticating ? 'Logging in...' : 'Login'}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Stack>

      <Box>
        <Typography variant="body2">
          Don’t have an account?{' '}
          <Link href={'/signup'} style={{ color: '#25858E' }}>
            Sign Up
          </Link>
        </Typography>
      </Box>
    </Layout>
  );
};

export default Login;
