import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { setupAxiosInterceptor } from 'src/hooks/useApiHook';
import { store } from 'src/redux/store';
import { RootState } from 'src/redux/reducers';
import { ProtectedRoute } from 'src/utils/auth';
import { Login, Signup, SSOAuthenticationCallback } from 'src/pages/auth';
import WelcomeScreen from 'src/pages/Welcome';
import { LandingPage } from 'src/pages/LandingPage';
import { useAppDispatch } from 'src/hooks/reduxHooks';
import { setIsSmallScreen } from 'src/redux/reducers/userDeviceSlice';
import { useMediaQuery } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { theme } from '@ikhokha/commons-ui';
import { InternalWelcome } from 'src/pages/InternalWelcome';
import { ErrorPage } from 'src/pages/error/ErrorPage';

const router = createBrowserRouter([
  { path: '/', element: <Login /> },
  { path: '/signup', element: <Signup /> },
  { path: '/sso/auth/callback', element: <SSOAuthenticationCallback /> },
  { path: '/internal-welcome', element: <InternalWelcome /> },
  {
    path: '/welcome',
    element: <ProtectedRoute component={<WelcomeScreen />}></ProtectedRoute>,
  },
  { path: '/landing-page', element: <LandingPage /> },
  { path: '/error', element: <ErrorPage /> },
]);

export default function App() {
  const dispatch = useAppDispatch();

  const appStore: ToolkitStore<RootState> = store;
  setupAxiosInterceptor(appStore);

  dispatch(
    setIsSmallScreen({
      isSmallScreen: useMediaQuery(theme.breakpoints.down('sm')),
    }),
  );

  return <RouterProvider router={router} />;
}
