import { AxiosResponse } from 'axios';
import { useApi } from '../../hooks/useApiHook';
import {
  GenerateReferralCodeRequest,
  GenerateReferralCodeResponse,
} from '../../types/referralCodeActionsTypes';
import { setReferralCode } from '../reducers/referralCodeSlice';
import { AppDispatch } from '../store';

/**
 * Generate a referral code for a specific cognito username.
 *
 * @param {string} uuid - UUID of user from cognito (Cognito Username)
 * @returns {Promise<void>}
 */
export const generateReferralCode =
  (uuid: string) => async (dispatch: AppDispatch) => {
    try {
      const request: GenerateReferralCodeRequest = {
        referrerId: uuid,
      };

      const response: AxiosResponse<GenerateReferralCodeResponse> =
        await useApi().post('referral-codes', request);

      dispatch(setReferralCode({ referralCode: response.data.referralCode }));
    } catch (error) {
      console.error('GenerateReferralCode error: ', error);
    }
  };
