import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AuthState {
  token: string | null;
  name: string;
  email: string;
  isAuthenticating: boolean;
  authenticationSuccess: boolean;
  authenticationErrorMessage?: string;
}

const initialState: AuthState = {
  token: null,
  name: '',
  email: '',
  isAuthenticating: false,
  authenticationSuccess: false,
  authenticationErrorMessage: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLoggingIn: (
      state,
      action: PayloadAction<{ isAuthenticating: boolean }>,
    ) => {
      return {
        ...state,
        isAuthenticating: action.payload.isAuthenticating,
        authenticationSuccess: false,
        authenticationErrorMessage: undefined,
      };
    },
    userLogin: (state, action: PayloadAction<AuthState>) => {
      return {
        ...state,
        token: action.payload.token,
        name: action.payload.name,
        email: action.payload.email,
        isAuthenticating: action.payload.isAuthenticating,
        authenticationSuccess: action.payload.authenticationSuccess,
      };
    },
    userLogout: () => {
      // empty, state clearing happens in the root reducer
    },
    userLoginFailed: (
      state,
      action: PayloadAction<{ authenticationErrorMessage: string }>,
    ) => {
      return {
        ...state,
        isAuthenticating: false,
        authenticationSuccess: false,
        authenticationErrorMessage: action.payload.authenticationErrorMessage,
      };
    },
  },
});

export const { userLoggingIn, userLogin, userLogout, userLoginFailed } =
  authSlice.actions;

export default authSlice.reducer;
