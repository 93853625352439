import { Box, Container, Typography } from '@mui/material';
import { useAppSelector } from 'src/hooks/reduxHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { theme } from '@ikhokha/commons-ui';

type ILayoutProps = {
  headerText?: string;
  children: React.ReactNode;
};

export const Layout: React.FunctionComponent<ILayoutProps> = (props) => {
  const { isSmallScreen } = useAppSelector((state) => state.userDevice);

  return (
    <Container
      className="app"
      sx={{
        paddingLeft: '0px',
        paddingRight: '0px',
        maxWidth: {
          lg: '100%',
          md: 'md',
          sm: 'sm',
          xs: 'xs',
        },
      }}
    >
      <Box
        sx={
          isSmallScreen
            ? {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                flex: 1,
                marginTop: theme.spacing(4),
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                flex: 1,
                marginTop: theme.spacing(5),
              }
        }
      >
        {/* <Box>
            <Logo hasHeading={true} />
          </Box> */}
        <Box
          sx={
            isSmallScreen
              ? {
                  alignSelf: 'flex-start',
                  borderRadius: '16px',
                  background: '#FFF',
                  boxSizing: 'border-box',
                  paddingRight: theme.spacing(5),
                  paddingLeft: theme.spacing(2),
                  paddingTop: theme.spacing(5),
                  paddingBottom: theme.spacing(6),
                }
              : {
                  borderRadius: '16px',
                  background: '#FFF',
                  boxSizing: 'border-box',
                  paddingRight: theme.spacing(5),
                  paddingLeft: theme.spacing(5),
                  paddingTop: theme.spacing(5),
                  paddingBottom: theme.spacing(6),
                }
          }
        >
          <Container
            sx={{
              alignSelf: 'center',
              maxWidth: {
                xs: 'xs',
              },
            }}
          >
            <Box
              sx={{
                color: '#3F3E38',
                marginTop: theme.spacing(0.25),
              }}
            >
              <Typography variant="body1">{props.headerText}</Typography>
            </Box>
            {props.children}
          </Container>
        </Box>
      </Box>
    </Container>
  );
};
