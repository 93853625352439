/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { signup } from 'src/redux/actions/authActions';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { theme } from '@ikhokha/commons-ui';
import { emailValidation, passwordValidation } from 'src/utils/validation';
import { Layout } from 'src/components/layout/Layout';
import { FormikField } from 'src/components/Formik/FormikField';
import { ErrorMessage } from 'src/components/ErrorMessage';

export const Signup: React.FunctionComponent<{
  /* empty object*/
}> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    isAuthenticating,
    authenticationSuccess,
    authenticationErrorMessage,
  } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticating && authenticationSuccess) {
      navigate('/welcome');
    }
  }, [isAuthenticating]);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: emailValidation,
    password: passwordValidation,
  });

  const onSubmit = (values: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    const { firstName, lastName, email, password } = values;
    const _firstName = firstName.trim();
    const _lastName = lastName.trim();
    const _email = email.trim().toLowerCase();
    const _password = password.trim();
    dispatch(signup(`${_firstName} ${_lastName}`, _email, _password));
  };

  return (
    <Layout headerText="Create your new account">
      {authenticationErrorMessage && (
        <ErrorMessage message={authenticationErrorMessage} />
      )}

      <Stack
        sx={{
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnBlur={true}
        >
          {(formik: any) => {
            return (
              <Form>
                <Box>
                  <FormikField
                    label="First Name"
                    name="firstName"
                    type="text"
                  />
                  <FormikField label="Last Name" name="lastName" type="text" />
                  <FormikField label="Email" name="email" type="text" />
                  <FormikField
                    label="Password"
                    name="password"
                    type="password"
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    disabled={isAuthenticating || !formik.isValid}
                    size="large"
                  >
                    {isAuthenticating ? 'Signing up...' : 'Sign up'}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Stack>

      <Box>
        <Typography variant="body2">
          Already have an account?{' '}
          <Link href={'/'} style={{ color: '#25858E' }}>
            Login
          </Link>
        </Typography>
      </Box>
    </Layout>
  );
};
