import { CognitoUserPool } from 'amazon-cognito-identity-js';

const {
  REACT_APP_COGNITO_INTERNAL_USER_POOL_ID,
  REACT_APP_COGNITO_INTERNAL_USER_POOL_WEB_CLIENT_ID,
} = process.env;

export const useUserPool = new CognitoUserPool({
  UserPoolId: REACT_APP_COGNITO_INTERNAL_USER_POOL_ID ?? '',
  ClientId: REACT_APP_COGNITO_INTERNAL_USER_POOL_WEB_CLIENT_ID ?? '',
});
