/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { theme } from '@ikhokha/commons-ui';
import WarningAmberOutlined from 'src/assets/icons/WarningAmberOutlined.svg';

type IErrorMessageProps = {
  message: string;
};

export const ErrorMessage: React.FunctionComponent<IErrorMessageProps> = (
  props,
) => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        background: '#FCE4E9',
        width: '320px',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        color: '#000',
        flexShrink: 0,
      }}
    >
      <Box>
        <img src={WarningAmberOutlined} alt="warning" />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          marginLeft: theme.spacing(1),
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        }}
      >
        <Typography variant="subtitle2" color="error">
          {props.message}
        </Typography>
      </Box>
    </Box>
  );
};
