import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserDeviceState {
  isSmallScreen: boolean;
}

const initialState: UserDeviceState = {
  isSmallScreen: false,
};

const userDeviceSlice = createSlice({
  name: 'userDevice',
  initialState,
  reducers: {
    setIsSmallScreen: (state, action: PayloadAction<UserDeviceState>) => {
      return {
        ...state,
        isSmallScreen: action.payload.isSmallScreen,
      };
    },
  },
});

export const { setIsSmallScreen } = userDeviceSlice.actions;

export default userDeviceSlice.reducer;
