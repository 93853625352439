import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ReferralCodeState {
  referralCode: string;
}

const initialState: ReferralCodeState = {
  referralCode: '',
};

const referralCodeSlice = createSlice({
  name: 'referralCode',
  initialState,
  reducers: {
    setReferralCode: (state, action: PayloadAction<ReferralCodeState>) => {
      return {
        ...state,
        referralCode: action.payload.referralCode,
      };
    },
  },
});

export const { setReferralCode } = referralCodeSlice.actions;

export default referralCodeSlice.reducer;
