import { Container, Typography } from '@mui/material';

export const ErrorPage: React.FunctionComponent<{
  /* empty object */
}> = () => {
  return (
    <Container>
      <Typography variant="h4">
        An error occurred. We will investigate.
      </Typography>
    </Container>
  );
};
