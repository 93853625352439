import * as Yup from 'yup';

export const emailValidation = Yup.string()
  .matches(
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
    'Please enter a valid email address.',
  )
  .required();

export const passwordValidation = Yup.string()
  .min(8, 'Password needs to be at least 8 characters')
  .matches(/[A-Z]/, 'Password need to have a capital letter')
  .matches(/[a-z]/, 'Password need to have a lowercase letter')
  .matches(/\d/, 'Password need a number')
  .matches(/[@$!%*?&-]/, 'Password need a special character "@$!%*?&-"')
  .required();
