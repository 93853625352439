import { Container, Typography } from '@mui/material';

export const InternalWelcome: React.FunctionComponent<{
  /* empty object */
}> = () => {
  return (
    <Container>
      <Typography variant="h4">
        Thank you! Signup has been completed.
      </Typography>
      <Typography variant="body1">You may now close this tab.</Typography>
    </Container>
  );
};
