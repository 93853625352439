import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { useUserPool } from 'src/hooks/useCognitoUserPoolHook';
import { AppDispatch } from 'src/redux/store';
import {
  userLoggingIn,
  userLogin,
  userLoginFailed,
  userLogout,
} from 'src/redux/reducers/authSlice';
import { generateReferralCode } from 'src/redux/actions/referralCodeActions';

const findCognitoAttribute = (
  key: string,
  objArr: CognitoUserAttribute[],
): string | undefined => {
  const index = objArr.findIndex((obj) => obj.Name === key);

  if (index > -1) {
    return objArr[index].Value;
  }
};

export const signup =
  (name: string, email: string, password: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(userLoggingIn({ isAuthenticating: true }));

    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: 'name',
        Value: name,
      }),
    ];

    useUserPool.signUp(email, password, attributeList, [], (error, result) => {
      if (error || !result) {
        console.error('cognito sign up failed', error);
        dispatch(
          userLoginFailed({
            authenticationErrorMessage:
              error?.message ?? 'Unknown error occurred during signup.',
          }),
        );
        return;
      }

      dispatch(login(email, password, name));
    });
  };

export const login =
  (email: string, password: string, name?: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(userLoggingIn({ isAuthenticating: true }));

    let token: string | undefined;

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: useUserPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session: CognitoUserSession) => {
        token = session.getIdToken().getJwtToken();

        // get user details for state
        cognitoUser.getUserAttributes(
          (error, result: CognitoUserAttribute[] | undefined) => {
            if (error || result === undefined || token === undefined) {
              console.error('cognito get user attributes failed', error);
              dispatch(
                userLoginFailed({
                  authenticationErrorMessage:
                    error?.message ?? 'Unknown error occurred during login.',
                }),
              );
              return;
            }

            if (name === undefined) {
              name = findCognitoAttribute('name', result);
            }

            if (!name) {
              console.error('cognito user name is not defined');
              dispatch(
                userLoginFailed({
                  authenticationErrorMessage:
                    'Cognito user name is not defined during login.',
                }),
              );
              return;
            }

            dispatch(generateReferralCode(cognitoUser.getUsername()));
            dispatch(
              userLogin({
                token,
                name,
                email,
                isAuthenticating: false,
                authenticationSuccess: true,
              }),
            );
          },
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onFailure: (error: any) => {
        console.error('user authentication failed', error);
        dispatch(
          userLoginFailed({ authenticationErrorMessage: error.message }),
        );
      },
    });
  };

export const logout = () => async (dispatch: AppDispatch) => {
  const cognitoUser = useUserPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.signOut();
  }

  return dispatch(userLogout());
};
