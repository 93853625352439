import { useCookies } from '../hooks/useCookiesHook';

/**
 * Extract the URL params as a record.
 *
 * @returns {Record<string, string>} - Key value pairs of url parameters
 */
export const getURLParametersRecord = (): Record<string, string> => {
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  console.log('Browser Utils -> getURLParametersRecord:', params);
  return params;
};

/**
 * Store a browser cookie with the given name and value.
 *
 * @param {string} name - The name of the cookie
 * @param {string} value - Value to be stored in the cookie
 * @returns void
 */
export const setBrowserCookie = (name: string, value: string): void => {
  const expireDate = new Date();
  expireDate.setMonth(expireDate.getMonth() + 1);
  const domain = process.env.REACT_APP_DOMAIN ?? '';
  useCookies().set(name, value, { domain, expires: expireDate });
};

/**
 * Retrieve a browser cookie with the given name.
 *
 * @param {string} name - The name of the cookie
 * @returns {string} - The value of the cookie
 */
export const getBrowserCookie = (name: string): string => {
  return useCookies().get(name);
};
