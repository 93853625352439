import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from 'axios';
import { RootState } from 'src/redux/reducers';

axios.defaults.withCredentials = true;

const { REACT_APP_REFERRAL_API_DOMAIN } = process.env;

let axiosInstance: AxiosInstance | null = null;

export const useApi = (
  baseURL = REACT_APP_REFERRAL_API_DOMAIN,
  version = 'v1',
) => {
  if (axiosInstance !== null) {
    return axiosInstance;
  }

  axiosInstance = axios.create({
    baseURL: `${baseURL}/${version}/`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return axiosInstance;
};

export const setupAxiosInterceptor = (store: ToolkitStore<RootState>) => {
  useApi().interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const token = store.getState().auth.token;
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      } as AxiosRequestHeaders;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  useApi().interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    },
  );
};
