import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/reduxHooks';
import { useEffect } from 'react';

/**
 * Protect components to make sure only authenticated users can go to them.
 *
 * @param {JSX.Element} props - The component protected by the protected route
 * @returns {JSX.Element | null} - Returns the protected component or null if user is not authenticated
 */
const ProtectedRoute = (props: { component: JSX.Element }) => {
  const navigate = useNavigate();

  const { token } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);

  return token ? props.component : null;
};

/**
 * Parse a JWT token.
 *
 * @param { string } token - The JWT token to parse
 * @returns { unknown } - Returns a parsed JSON object
 */
const parseJwt = (token: string): Record<string, string> => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export { ProtectedRoute, parseJwt };
