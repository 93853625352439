import axios, { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GenerateReferralCodeRequest,
  GenerateReferralCodeResponse,
} from 'src/types/referralCodeActionsTypes';
import { parseJwt } from 'src/utils/auth';
import { getURLParametersRecord } from 'src/utils/browser';

const {
  REACT_APP_COGNITO_USER_POOL_DOMAIN_INTERNAL,
  REACT_APP_COGNITO_INTERNAL_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_DOMAIN,
  REACT_APP_REFERRAL_API_DOMAIN,
} = process.env;

export const SSOAuthenticationCallback: React.FunctionComponent<{
  /* empty object */
}> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const getTokens = async () => {
      const response = await axios.post(
        `${REACT_APP_COGNITO_USER_POOL_DOMAIN_INTERNAL}/oauth2/token`,
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );

      return response;
    };

    const createReferralCode = async (token: string) => {
      const decodedToken = parseJwt(token);

      const request: GenerateReferralCodeRequest = {
        referrerId: decodedToken['cognito:username'],
      };

      const response: AxiosResponse<GenerateReferralCodeResponse> =
        await axios.post(
          `${REACT_APP_REFERRAL_API_DOMAIN}/v1/referral-codes`,
          request,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              internal: true,
            },
          },
        );

      return response;
    };

    const { code } = getURLParametersRecord();

    if (!code) {
      throw new Error('Authorization code not found');
    }

    const params = new URLSearchParams([
      ['grant_type', 'authorization_code'],
      ['client_id', REACT_APP_COGNITO_INTERNAL_USER_POOL_WEB_CLIENT_ID ?? ''],
      ['code', code],
      [
        'redirect_uri',
        `https://referral.${REACT_APP_DOMAIN}/sso/auth/callback`,
      ],
    ]);

    getTokens()
      .then((response) => {
        createReferralCode(response.data.id_token)
          .then(() => {
            navigate('/internal-welcome');
          })
          .catch((error: unknown) => {
            console.error(`Error occurred: ${JSON.stringify(error)}`);
            navigate('/error');
          });
      })
      .catch((error) => {
        console.error('Error exchanging code for tokens:', error);
      });
  });

  return null;
};
