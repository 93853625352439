import { useEffect } from 'react';
import { getURLParametersRecord, setBrowserCookie } from '../utils/browser';

export const LandingPage: React.FunctionComponent<{
  /* empty object */
}> = () => {
  useEffect(() => {
    const redirect = async (): Promise<void> => {
      try {
        window.location.href =
          process.env.REACT_APP_REDIRECT_URL ??
          'https://onboarding.ikhokha.io/';
      } catch (error) {
        console.error('Error during redirection:', error);
      }
    };

    let { referralCode } = getURLParametersRecord();
    referralCode = referralCode.toUpperCase();
    setBrowserCookie('referralCode', referralCode);
    redirect();
  }, []);

  return null;
};
