/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { theme } from '@ikhokha/commons-ui';
import { Field } from 'formik';
import { FormikErrorMessage } from './FormikErrorMessage';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type IFormikFieldProps = {
  name: string;
  label: string;
  type?: 'text' | 'password';
};

export const FormikField: React.FunctionComponent<IFormikFieldProps> = (
  props,
) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const type =
    (props.type === 'password' && showPassword) || props.type === 'text'
      ? 'text'
      : 'password';

  const inputProps =
    props.type === 'password'
      ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{
                  color: 'black',
                  marginRight: theme.spacing(0),
                }}
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }
      : {};

  return (
    <Box sx={{ marginBottom: theme.spacing(2) }}>
      <Field name={props.name}>
        {(formikField: any) => {
          return (
            <>
              <TextField
                InputProps={inputProps}
                id={props.name}
                label={props.label}
                variant="standard"
                size="medium"
                type={type}
                {...formikField.field}
              />
              <FormikErrorMessage name={props.name}></FormikErrorMessage>
            </>
          );
        }}
      </Field>
    </Box>
  );
};
