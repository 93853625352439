import Cookies from 'universal-cookie';

let cookiesInstance: Cookies | null = null;

export const useCookies = (): Cookies => {
  if (cookiesInstance !== null) {
    return cookiesInstance;
  }

  cookiesInstance = new Cookies();
  return cookiesInstance;
};
