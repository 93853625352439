/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHelperText } from '@mui/material';
import { ErrorMessage } from 'formik';

type IFormikErrorMessageProps = {
  name: string;
};

export const FormikErrorMessage: React.FunctionComponent<
  IFormikErrorMessageProps
> = (props) => {
  return (
    <ErrorMessage name={props.name}>
      {(errMessage: any) => {
        return <FormHelperText error={true}>{errMessage}</FormHelperText>;
      }}
    </ErrorMessage>
  );
};
